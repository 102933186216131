// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-become-a-member-jsx": () => import("./../../../src/pages/become-a-member.jsx" /* webpackChunkName: "component---src-pages-become-a-member-jsx" */),
  "component---src-pages-collaboration-jsx": () => import("./../../../src/pages/collaboration.jsx" /* webpackChunkName: "component---src-pages-collaboration-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-practical-facilitation-training-jsx": () => import("./../../../src/pages/practical-facilitation-training.jsx" /* webpackChunkName: "component---src-pages-practical-facilitation-training-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reports-jsx": () => import("./../../../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-request-a-speaker-jsx": () => import("./../../../src/pages/request-a-speaker.jsx" /* webpackChunkName: "component---src-pages-request-a-speaker-jsx" */),
  "component---src-pages-talking-mats-jsx": () => import("./../../../src/pages/talking-mats.jsx" /* webpackChunkName: "component---src-pages-talking-mats-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-video-jsx": () => import("./../../../src/pages/video.jsx" /* webpackChunkName: "component---src-pages-video-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */),
  "component---src-pages-who-we-are-jsx": () => import("./../../../src/pages/who-we-are.jsx" /* webpackChunkName: "component---src-pages-who-we-are-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../../../src/templates/NewsArticle.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */)
}

